import { extendObservable, runInAction } from 'mobx'
import { stringify } from 'querystring'

const debug = require('debug')('qm:NotificationStore')

export default class NotificationStore {
  constructor (rootStore) {
    this.rootStore = rootStore
    extendObservable(this, {
      loading: null,
      error: null,
      notifications: new Map(),
      hideRead: false,
      lastCheck: null,
      virtual: true,
      get unreadCount () {
        return [...this.notifications.values()]
          .filter(notification => {
            const context = this.rootStore.preference.notification.get('inApp')
            const allowed = context ? (context.get(notification.action) === true) : false
            return allowed
          })
          .filter(n => !n.viewed).length
      },
      get sortedNotifications () {
        return new Map(
          [...this.notifications.entries()]
            .filter(([, notification]) => {
              const context = this.rootStore.preference.notification.get('inApp')
              const allowed = context ? (context.get(notification.action) === true) : false
              return allowed
            })
            .filter(([, notification]) => this.hideRead ? !notification.viewed : true)
            .sort((A, B) => {
              const a = A[1].created
              const b = B[1].created
              return a === b ? 0 : a > b ? -1 : 1
            })
            .map(([oldIndex, notification], newIndex) => [newIndex, notification])
        )
      }
    })
  }

  get (k) { return this.notifications.get(k) }
  set (k, v) { return this.notifications.set(k, v) }
  has (k) { return this.notifications.has(k) }

  async markAsRead (k) {
    const { api, search } = this.rootStore
    runInAction(() => {
      if (Array.isArray(k)) {
        k.forEach(k => { this.get(k).viewed = true })
      } else {
        this.get(k).viewed = true
      }
      this.loading = true
      this.error = false
    })
    try {
      const query = {}
      if (search.adminMode) query.adminMode = true
      const qs = stringify(query)
      debug('qs', qs)
      await api.post('notifications/mark-as-read' + (qs ? '?' + qs : ''), Array.isArray(k) ? k : [k])
      runInAction(() => {
        this.loading = false
      })
    } catch (e) {
      runInAction(() => {
        if (Array.isArray(k)) {
          k.forEach(k => { this.get(k).viewed = false })
        } else {
          this.get(k).viewed = false
        }
        this.loading = false
        this.error = e
      })
    }
  }

  async markAsUnread (k) {
    const { api, search } = this.rootStore
    runInAction(() => {
      if (Array.isArray(k)) {
        k.forEach(k => { this.get(k).viewed = false })
      } else {
        this.get(k).viewed = false
      }
      this.loading = true
      this.error = false
    })
    try {
      const query = {}
      if (search.adminMode) query.adminMode = true
      const qs = stringify(query)
      debug('qs', qs)
      await api.post('notifications/mark-as-unread' + (qs ? '?' + qs : ''), Array.isArray(k) ? k : [k])
      runInAction(() => {
        this.loading = false
      })
    } catch (e) {
      runInAction(() => {
        if (Array.isArray(k)) {
          k.forEach(k => { this.get(k).viewed = true })
        } else {
          this.get(k).viewed = true
        }
        this.loading = false
        this.error = e
      })
    }
  }

  // Transform array of notifications into internal map format
  replace (notifications = []) {
    runInAction(() => {
      this.notifications.replace(notifications.map(n => [n.id, n]))
    })
  }

  // Add an array of notifications
  add (notifications = []) {
    debug('add', notifications)
    runInAction(() => {
      notifications.map(n => {
        // debug('notifications p', p)
        return this.notifications.set(n.id, n)
      })
    })
  }

  startPolling () {
    clearTimeout(this.notificationTimeout)
    this.notificationTimeout = setTimeout(async _ => {
      try {
        await this.fetch()
      } catch (e) {
        debug('fetch error during polling', e)
      }
      this.startPolling()
    }, 60 * 1000)
  }

  stopPolling () {
    clearTimeout(this.notificationTimeout)
  }

  async fetch (resetLastCheck = false) {
    debug('fetch')
    const { api, preference } = this.rootStore
    runInAction(() => {
      if (resetLastCheck) this.lastCheck = null
      this.loading = true
      this.error = false
    })
    try {
      const start = new Date()
      const query = { limit: 1000 }
      if (this.rootStore.search.adminMode) query.adminMode = true
      if (this.lastCheck) query.since = this.lastCheck.toISOString()
      // if (preference.flagCandidate) query.flagCandidate = true
      const inAppPrefs = [...preference.notification.get('inApp').entries()]
      inAppPrefs.forEach(([key, value]) => { query[key] = value })
      const qs = stringify(query)
      debug('qs', qs)
      const notifications = await api.get('notifications' + (qs ? '?' + qs : ''))
      runInAction(() => {
        this.lastCheck = start
        this.loading = false
        if (resetLastCheck) this.replace(notifications)
        else this.add(notifications)
      })
    } catch (e) {
      runInAction(() => {
        this.loading = false
        this.error = e
      })
    }
  }
}

debug('loaded')
