import React from 'react'
import { FaSpinner } from 'react-icons/fa'
import './Loading.css'

export default class Loading extends React.PureComponent {
  render () {
    return (
      <div className='loader-outer'>
        <div className='loader-inner'>
          <FaSpinner style={{ color: '#35784E', fontSize: '72px' }} className='fa-pulse' />
        </div>
      </div>
    )
  }
}

export class LoadingInline extends React.PureComponent {
  render () {
    return (
      <FaSpinner style={{ color: '#35784E' }} className='fa-pulse' />
    )
  }
}
