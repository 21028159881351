import { extendObservable, runInAction } from 'mobx'
import { ObjectID } from 'bson'
import { givenNames, familyNames, companyNames } from 'quantmatch-common'
import moment from 'moment'

const debug = require('debug')('qm:PersonStore')

export default class PersonStore {
  constructor (rootStore) {
    extendObservable(this, {
      people: new Map()
    })
  }

  get (k) { return this.people.get(k) }
  set (k, v) { return this.people.set(k, v) }
  has (k) { return this.people.has(k) }

  // Transform array of people into internal map format
  replace (people = []) {
    runInAction(() => {
      this.people.replace(people.map(p => [p.id, p]))
    })
  }

  // Add an array of people
  add (people = []) {
    debug('add', people)
    runInAction(() => {
      people.map(p => {
        // debug('people p', p)
        return this.people.set(p.id, p)
      })
    })
  }

  randomize (count = 100) {
    const people = (new Array(count)).fill({}).map(() => {
      const id = (new ObjectID()).toString()
      const givenName = givenNames[Math.floor(Math.random() * givenNames.length)]
      const familyName = familyNames[Math.floor(Math.random() * familyNames.length)]
      const companyName = companyNames[Math.floor(Math.random() * companyNames.length)]
      const email = `${givenName}.${familyName}@example.com`
      const cv = {
        url: `s3://${id}.pdf`,
        updated: moment().subtract(Math.random() * 365, 'days').toDate()
      }
      return { id, familyName, givenName, companyName, email, cv }
    })
    debug('randomizePeople', people)
    this.replace(people)
  }
}

debug('loaded')
