"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validatePermissions = validatePermissions;
exports.Permissions = exports.orgRoles = exports.globalRoles = void 0;

//
// Role / Permission definitions
//
const debug = require('debug')('qm:permissions'); // These should not have the same names as organization roles. If they do,
// any user with the global role will automatically have access to org role.


const globalRoles = [{
  name: 'Admin',
  description: 'access to everything'
}, {
  name: 'Potential Candidates',
  description: 'see potential candidates'
}, {
  name: 'Lite',
  description: 'restrict to lite view'
}];
exports.globalRoles = globalRoles;
const orgRoles = [{
  name: 'Manager'
}, {
  name: 'Recruiter'
}]; //
// Validation
//

exports.orgRoles = orgRoles;
const validGlobalRoles = globalRoles.map(R => R.name);
const validOrgRoles = orgRoles.map(R => R.name);
const validObjectId = /^[0-9a-fA-F]{24}$/;
const specialKeys = new Set(['roles', 'searches', 'hiringManagers']);

function validateOrgPermissions(orgPermissions) {
  // Check roles
  if (Array.isArray(orgPermissions)) {
    orgPermissions.forEach(r => {
      if (validOrgRoles.indexOf(r) === -1) throw new Error(`Invalid org role ${r}`);
    });
  } else {
    throw new Error('Org permissions must be an array');
  }
}
/**
 * Validates a permissions object. Returns true on success and throws an Error
 * if permissions fails check.
 */


function validatePermissions(permissions) {
  // Check keys
  Object.keys(permissions).forEach(k => {
    if (!(specialKeys.has(k) || validObjectId.test(k))) throw new Error(`Invalid global key ${k}`);
  }); // Check roles

  if (permissions.roles) {
    if (!Array.isArray(permissions.roles)) throw new Error('"roles" must be an array');
    permissions.roles.forEach(r => {
      if (validGlobalRoles.indexOf(r) === -1) throw new Error(`Invalid global role ${r}`);
    });
  } // Check searches


  if (permissions.searches) {
    if (!Array.isArray(permissions.searches)) throw new Error('"searches" must be an array');
    permissions.searches.forEach(search => {
      if (!validObjectId.test(search)) throw new Error(`Invalid search ${search}`);
    });
  } // Check hiringManagers


  if (permissions.hiringManagers) {
    if (!Array.isArray(permissions.hiringManagers)) throw new Error('"hiringManagers" must be an array');
    permissions.hiringManagers.forEach(hiringManager => {
      if (!validObjectId.test(hiringManager)) throw new Error(`Invalid hiringManager ${hiringManager}`);
    });
  } // Check organizations


  Object.keys(permissions).forEach(k => {
    if (!specialKeys.has(k)) validateOrgPermissions(permissions[k]);
  });
}
/**
 * Validates a permissions object and provides functions to check and modify permissions.
 */


class Permissions {
  /**
   * @permissions is either a javascript object or a JSON encoded string.
   *
   * Internal Format:
   * {
   *   '123456789012345678901234': ['Manager', 'Recruiter'],
   *   roles: ['Admin'],
   *   searches: ['123456789012345678901234'],
   *   hiringManagers: ['123456789012345678901234']
   * }
   */
  constructor(permissions = {}) {
    this.permissions = permissions !== null && typeof permissions === 'object' ? permissions : JSON.parse(permissions || '{}');
    validatePermissions(this.permissions);
  }
  /**
   * Returns a long-format representation of permissions which is an array of roles in
   * the following format, suitable for use in a mongo index:
   * [
   *   { type: 'Global', role: 'Admin' },
   *   { type: 'Org', role: 'Manager', org: '123456789012345678901234' },
   *   { type: 'Org', role: 'Recruiter', org: '123456789012345678901234' }
   *   { type: 'Search', search: '123456789012345678901234' }
   *   { type: 'Hiring Manager', person: '123456789012345678901234' }
   * ]
   */


  longFormat() {
    validatePermissions(this.permissions);
    const result = [];
    Object.keys(this.permissions).forEach(org => {
      if (org === 'roles') {
        this.permissions.roles.forEach(role => result.push({
          type: 'Global',
          role
        }));
      } else if (org === 'searches') {
        this.permissions.searches.forEach(search => result.push({
          type: 'Search',
          search
        }));
      } else if (org === 'hiringManagers') {
        this.permissions.hiringManagers.forEach(person => result.push({
          type: 'Hiring Manager',
          person
        }));
      } else {
        this.permissions[org].forEach(role => result.push({
          type: 'Org',
          role,
          org
        }));
      }
    });
    return result;
  }
  /**
   * Returns a list of organization ids.
   */


  getOrgIDs() {
    validatePermissions(this.permissions);
    return Object.keys(this.permissions).filter(org => !specialKeys.has(org));
  }
  /**
   * Returns a list of specific search ids.
   */


  getSearchIDs() {
    validatePermissions(this.permissions);
    return this.permissions.searches || [];
  }
  /**
   * Returns a list of hiring manager ids.
   */


  getHiringManagerIDs() {
    validatePermissions(this.permissions);
    return this.permissions.hiringManagers || [];
  }
  /**
   * Serializes the permissions object to a string. Throws an Error if the permissions
   * object is invalid.
   */


  toString() {
    validatePermissions(this.permissions);
    return JSON.stringify(this.permissions);
  }
  /**
   * Adds a specific search, mutating this permissions object.
   */


  addSearch(search) {
    if (!validObjectId.test(search)) throw new Error(`Invalid search ${search}`);
    if (!this.permissions.searches) this.permissions.searches = [search];else if (this.permissions.searches.indexOf(search) === -1) this.permissions.searches.push(search);
  }
  /**
   * Removes a specific search, mutating this permissions object.
   */


  removeSearch(search) {
    if (this.permissions.searches) {
      const ix = this.permissions.searches.indexOf(search);
      if (ix !== -1) this.permissions.searches.splice(ix, 1);
      if (this.permissions.searches.length === 0) delete this.permissions.searches;
    }
  }
  /**
   * Adds a specific hiring manager, mutating this permissions object.
   */


  addHiringManager(hm) {
    if (!validObjectId.test(hm)) throw new Error(`Invalid hiring manager ${hm}`);
    if (!this.permissions.hiringManagers) this.permissions.hiringManagers = [hm];else if (this.permissions.hiringManagers.indexOf(hm) === -1) this.permissions.hiringManagers.push(hm);
  }
  /**
   * Removes a specific hiring manager, mutating this permissions object.
   */


  removeHiringManager(hm) {
    if (this.permissions.hiringManagers) {
      const ix = this.permissions.hiringManagers.indexOf(hm);
      if (ix !== -1) this.permissions.hiringManagers.splice(ix, 1);
      if (this.permissions.hiringManagers.length === 0) delete this.permissions.hiringManagers;
    }
  }
  /**
   * Adds a global role, mutating this permissions object.
   */


  addGlobalRole(role) {
    if (validGlobalRoles.indexOf(role) === -1) throw new Error(`Invalid global role ${role}`);
    if (!this.permissions.roles) this.permissions.roles = [role];else if (this.permissions.roles.indexOf(role) === -1) this.permissions.roles.push(role);
  }
  /**
   * Removes a global role, mutating this permissions object.
   */


  removeGlobalRole(role) {
    if (this.permissions.roles) {
      const ix = this.permissions.roles.indexOf(role);
      if (ix !== -1) this.permissions.roles.splice(ix, 1);
      if (this.permissions.roles.length === 0) delete this.permissions.roles;
    }
  }
  /**
   * Adds an organization role, mutating this permissions object.
   */


  addOrgRole(role, org) {
    if (!validObjectId.test(org)) throw new Error(`Invalid org ${org}`);
    if (validOrgRoles.indexOf(role) === -1) throw new Error(`Invalid org role ${role}`);
    if (!this.permissions[org]) this.permissions[org] = [role];else if (this.permissions[org].indexOf(role) === -1) this.permissions[org].push(role);
  }
  /**
   * Removes an organization role, mutating this permissions object.
   */


  removeOrgRole(role, org) {
    if (this.permissions[org]) {
      const ix = this.permissions[org].indexOf(role);
      if (ix !== -1) this.permissions[org].splice(ix, 1);
      if (this.permissions[org].length === 0) delete this.permissions[org].roles;
      if (Object.keys(this.permissions[org]).length === 0) delete this.permissions[org];
    }
  }
  /**
   * Returns true if the user has the given specific search.
   */


  hasSearch(search) {
    return this.permissions.searches && this.permissions.searches.indexOf(search) !== -1;
  }
  /**
   * Returns true if the user has the given specific hiring manager.
   */


  hasHiringManager(hm) {
    return this.permissions.hiringManagers && this.permissions.hiringManagers.indexOf(hm) !== -1;
  }
  /**
   * Accepts any role (global, org) and returns true if user has the role
   * in any context. @org is optional
   */


  hasRole(role, org) {
    return this.hasGlobalRole(role) || org && this.hasOrgRole(role, org);
  }
  /**
   * Returns true if the user has the given global role.
   */


  hasGlobalRole(role) {
    return validGlobalRoles.indexOf(role) !== -1 && this.permissions.roles && this.permissions.roles.indexOf(role) !== -1;
  }
  /**
   * Returns true if the user has any role in the given organization.
   */


  hasAnyOrgRole(org) {
    if (!org) return false;
    if (!validObjectId.test(org)) throw new Error(`Invalid org ${org}`);
    return this.permissions[org] && this.permissions[org].length > 0;
  }
  /**
   * Returns true if the user has the given role in the organization.
   */


  hasOrgRole(role, org) {
    if (!validObjectId.test(org)) throw new Error(`Invalid org ${org}`);
    return validOrgRoles.indexOf(role) !== -1 && this.permissions[org] && this.permissions[org].indexOf(role) !== -1;
  }
  /**
   * Returns true if the user has the given role in any organization.
   */


  hasRoleInAnyOrg(role) {
    return this.getOrgIDs().some(org => this.hasOrgRole(role, org));
  }
  /**
   * Returns true if user has any explicit permissions (Admin, Potential Candidates, etc. don't count)
   */


  hasExplicitPermissions() {
    return this.getOrgIDs().length > 0 || this.getHiringManagerIDs().length > 0 || this.getSearchIDs().length > 0;
  }

}

exports.Permissions = Permissions;
debug('loaded');