import React from 'react'
import { MobXProviderContext } from 'mobx-react'

import AuthStore from './AuthStore'
import ApiStore from './ApiStore'
import PersonStore from './PersonStore'
import SearchStore from './SearchStore'
import CandidateStore from './CandidateStore'
import PersonSearchStore from './PersonSearchStore'
import UserStore from './UserStore'
import LogoStore from './LogoStore'
import OrganizationStore from './OrganizationStore'
import SearchStatusStore from './SearchStatusStore'
import NotificationStore from './NotificationStore'
import PreferenceStore from './PreferenceStore'
import AnalyticsStore from './AnalyticsStore'
import ReadOnlySearchStore from './ReadOnlySearchStore'

const debug = require('debug')('qm:RootStore')

export default class RootStore {
  constructor () {
    this.init()
  }
  init () {
    this.searchStatus = new SearchStatusStore(this)
    this.auth = new AuthStore(this)
    this.api = new ApiStore(this)
    this.person = new PersonStore(this)
    this.search = new SearchStore(this)
    this.roSearch = new ReadOnlySearchStore(this)
    this.personSearch = new PersonSearchStore(this)
    this.user = new UserStore(this)
    this.logo = new LogoStore(this)
    this.organization = new OrganizationStore(this)
    this.candidate = new CandidateStore(this)
    this.notification = new NotificationStore(this)
    this.preference = new PreferenceStore(this)
    this.analytics = new AnalyticsStore(this)
    // this.organization.randomize()
    // this.person.randomize()
    this.auth.refresh(_ => {
      debug('init')
      this.auth.startRefreshing()
      this.search.fetch()
      // await this.notification.fetch()
      this.preference.fetch().then(_ => {
        this.notification.startPolling()
        this.notification.fetch()
      })
      // this.notification.startPolling()
      this.analytics.recordVisibility()
      this.analytics.startRecordingVisibility()
      this.analytics.startReporting()
      debug('init done')
    })
  }
}

export function useStores () {
  return React.useContext(MobXProviderContext)
}

debug('loaded')
