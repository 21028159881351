import { extendObservable, runInAction } from 'mobx'
import store from 'store'

const debug = require('debug')('qm:AnalyticsStore')

// By default store.each() does not return items in this namespace
// so this plugin hacks in that functionality.
const storage = store.namespace('qm_AnalyticsStore')
const namespaceItemsPlugin = function () {
  return {
    set: function (superFn, key, value) {
      return superFn(key, value)
    },
    namespaceItems: function () {
      const items = []
      store.each((value, key) => {
        // debug({value, key})
        if (this._namespacePrefix && this._namespaceRegexp.test(key)) {
          items.push([key.slice(this._namespacePrefix.length), value])
        }
      })
      return items
    }
  }
}
storage.addPlugin(namespaceItemsPlugin)
window.analyticsStorage = storage
window.store = store

//
// This class keeps analytics events both in itself in memory and in localStorage
// or whatever the store library can use.
//
export default class AnalyticsStore {
  constructor (rootStore) {
    this.rootStore = rootStore
    extendObservable(this, {
      reporting: null,
      error: null,
      events: new Map(),
      lastSent: null
    })
    this.loadUnsentEventsFromStorage()
  }

  recordEvent (eventType, data) {
    // Skip extra replace that coems from back buttons
    if (eventType === 'navigation' && data && data.action === 'REPLACE' && data.location && data.location.pathname === '/') return
    const timestamp = Date.now()
    const key = Date.now() + Math.random()
    const event = { eventType, timestamp, data }
    storage.set(key, event)
    this.events.set(key, Object.assign(event, { sent: false }))
    debug('recordEvent', { key, event })
  }

  loadUnsentEventsFromStorage () {
    const unsentEvents = []
    storage.namespaceItems().map(([key, event]) =>
      unsentEvents.push([key, Object.assign(event, { sent: false })])
    )
    console.log({ unsentEvents })
    runInAction(() => this.events.replace(unsentEvents))
  }

  storageGetEventsMapBeforeDate (before, limit = 100) {
    const events = new Map()
    const time = before.getTime()
    const items = storage.namespaceItems()
    for (let i = 0; i < items.length; i++) {
      const [ key, event ] = items[i]
      if (parseInt(key, 10) <= time) events.set(key, event)
      if (events.size >= limit) break
    }
    return events
  }

  storageRemoveEventsByKey (keys) {
    runInAction(() => {
      keys.forEach(key => {
        storage.remove(key)
        // Mark mobx events as true, leaving them in memory
        this.events.get(key).sent = true
      })
    })
  }

  storageGetEventsBeforeDate (before) {
    const events = []
    const time = before.getTime()
    storage.namespaceItems().map(([key, event]) =>
      parseInt(key, 10) <= time && events.push(event)
    )
    return events
  }

  storageRemoveEventsBeforeDate (before) {
    const keys = []
    const time = before.getTime()
    storage.namespaceItems().map(([key, event]) =>
      parseInt(key, 10) <= time && keys.push(key)
    )
    runInAction(() => {
      keys.forEach(key => {
        storage.remove(key)
        // Mark mobx events as true, leaving them in memory
        this.events.get(key).sent = true
      })
    })
  }

  recordVisibility () {
    const { visibilityState } = document
    const { pathname, search, hash } = window.location
    const location = { pathname, search, hash }
    this.recordEvent('visibility', { visibilityState, location })
  }

  startRecordingVisibility () {
    clearTimeout(this.visibilityTimeout)
    this.visibilityTimeout = setTimeout(async _ => {
      try {
        this.recordVisibility()
      } catch (e) {
        debug('fetch error during recordVisibility', e)
      }
      this.startRecordingVisibility()
    }, 60 * 1000)
  }

  stopRecordingVisibility () {
    clearTimeout(this.visibilityTimeout)
  }

  startReporting () {
    clearTimeout(this.reportTimeout)
    this.reportTimeout = setTimeout(async _ => {
      try {
        await this.report()
      } catch (e) {
        debug('fetch error during reporting', e)
      }
      this.startReporting()
    }, 60 * 1000)
  }

  stopReporting () {
    clearTimeout(this.reportTimeout)
  }

  clear () {
    runInAction(() => {
      storage.namespaceItems().forEach(([key, event]) => storage.remove(key))
      this.events.clear()
    })
  }

  async report () {
    debug('report')
    const { api } = this.rootStore
    if (this.reporting) return debug('attempted to report while a report was outstanding')
    const start = new Date()
    const events = this.storageGetEventsMapBeforeDate(start)
    if (events.size === 0) return debug('no events to report')
    runInAction(() => { this.reporting = true; this.error = false })
    try {
      const result = await api.post('analytics', [...events.values()])
      debug({ result })
      runInAction(() => {
        this.lastSent = start
        this.reporting = false
        this.storageRemoveEventsByKey([...events.keys()])
      })
    } catch (e) {
      runInAction(() => {
        this.reporting = false
        this.error = e
      })
    }
  }
}

debug('loaded')
