import React from 'react'
import RootStore from './stores/RootStore'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { Provider, observer } from 'mobx-react'
import { createBrowserHistory } from 'history'

import asyncComponent from './components/AsyncComponent'
import './App.css'

const debug = require('debug')('qm:App')

const AsyncAuth = asyncComponent(() => import('./scenes/Auth'))
const AsyncDashboard = asyncComponent(() => import('./scenes/Dashboard'))
const AsyncLite = asyncComponent(() => import('./scenes/Lite'))
const AsyncAdmin = asyncComponent(() => import('./scenes/Admin'))

const stores = new RootStore()
window.stores = stores

// Make sure root appears in our history so our back buttons work
// when visiting pages via direct link
const history = createBrowserHistory()
window.browserHistory = history
if (history.location.pathname !== '/') {
  const oldLocation = Object.assign({}, history.location)
  history.replace('/')
  history.push(oldLocation)
}

// Navigation analytics
stores.analytics.recordEvent('navigation', { location: history.location, action: 'DIRECT', referrer: document.referrer })
const unlisten = history.listen((location, action) => {
  stores.analytics.recordEvent('navigation', { location, action })
})
debug({ unlisten })

export default observer(class App extends React.Component {
  render () {
    const { auth } = stores
    return <Router history={history}>
      <Provider stores={stores}>
        { auth.state === auth.states.loggedIn
          ? <Switch>
            <Route path='/lite' component={AsyncLite} />
            <Route path='/admin' component={AsyncAdmin} />
            <Route>
              { auth.permissions && auth.permissions.hasGlobalRole('Lite') && !auth.permissions.hasGlobalRole('Admin')
                ? <Redirect to='/lite' />
                : <AsyncDashboard /> }
            </Route>
          </Switch>
          : <AsyncAuth /> }
      </Provider>
    </Router>
  }
})

debug('loaded')
