import React from 'react'
import ReactDOM from 'react-dom'
// import registerServiceWorker from './registerServiceWorker'
import App from './App'
import './index.css'

const debug = require('debug')('qm:index')

ReactDOM.render(<App />, document.getElementById('root'))

// registerServiceWorker()

debug('loaded')
