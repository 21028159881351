import { extendObservable, runInAction } from 'mobx'
import qs from 'querystring'

const debug = require('debug')('qm:PersonSearchStore')

export default class PersonSearchStore {
  constructor (rootStore) {
    this.rootStore = rootStore
    extendObservable(this, {
      people: new Map(),
      q: '',
      personName: '',
      givenName: '',
      familyName: '',
      orgName: '',
      officialTitle: '',
      qHistory: new Map(),
      orgs: [],
      loading: null,
      error: null
    })
  }

  get (personID) { return this.people.get(personID) }

  setQ (q) {
    runInAction(() => {
      this.q = q
      if (q) this.qHistory.set(q, true)
    })
  }

  setPersonName (s) { runInAction(() => { this.personName = s }) }
  setGivenName (s) { runInAction(() => { this.givenName = s }) }
  setFamilyName (s) { runInAction(() => { this.familyName = s }) }
  setOrgName (s) { runInAction(() => { this.orgName = s }) }
  setOfficialTitle (s) { runInAction(() => { this.officialTitle = s }) }

  setOrgs (organizationIds) {
    debug('organizationIds', organizationIds)
    const orgs = organizationIds || []
    runInAction(() => {
      this.orgs.replace(orgs.filter(o => o))
    })
  }

  // Transform array of candidates into internal map format
  replace (people = []) {
    runInAction(() => {
      const maxScore = Math.max(1, Math.max(...people.map(p => p.score * p.score * p.score || 0)))
      this.people.replace(people.map(p => [p.id, Object.assign({ relevance: 100 * p.score * p.score * p.score / maxScore }, p)]))
    })
  }

  async fetch () {
    const { api } = this.rootStore
    if (this.loading) return
    runInAction(() => { this.loading = true; this.error = false })
    try {
      const query = { includeOrgs: true }
      if (this.q && this.q.length > 2) query['q'] = this.q
      if (this.orgs.length) query['org'] = this.orgs.map(org => org.id)
      if (this.personName) query.personName = this.personName
      if (this.familyName) query.familyName = this.familyName
      if (this.givenName) query.givenName = this.givenName
      if (this.orgName) query.orgName = this.orgName
      if (this.officialTitle) query.officialTitle = this.officialTitle
      const s = qs.stringify(query)
      const q = s ? ('?' + s) : ''
      debug('qs', q)
      const { people, orgs } = await api.get(`people` + q)
      debug('returned', { people })
      this.replace(people)
      this.rootStore.organization.add(orgs)
      runInAction(() => {
        this.loading = false
      })
    } catch (e) {
      debug(e)
      runInAction(() => {
        this.loading = false
        this.error = e
      })
    }
  }
}

debug('loaded')
