import { extendObservable, runInAction } from 'mobx'
import { notificationContexts, notificationActions } from 'quantmatch-common'

const debug = require('debug')('qm:PreferenceStore')

// debug('notificationContexts', notificationContexts)
// debug('notificationActions', notificationActions)

export default class PreferenceStore {
  constructor (rootStore) {
    this.rootStore = rootStore
    extendObservable(this, {
      error: null,
      loading: null,
      saving: null,
      notification: new Map(
        notificationContexts.map(context =>
          [context, new Map(
            notificationActions.map(action =>
              [action, false]
            )
          )]
        )
      )
    })
  }

  async setNotification (context, action, checked) {
    const { api, notification } = this.rootStore
    runInAction(() => {
      this.notification.get(context).set(action, checked)
      this.saving = true
      this.error = false
    })
    try {
      const url = `preferences/notification/${context}/${action}`
      await (checked ? api.put(url) : api.delete(url))
      runInAction(() => { this.saving = false })
      notification.fetch(true)
    } catch (e) {
      runInAction(() => {
        this.saving = false
        this.error = e
      })
    }
  }

  async fetch () {
    debug('fetch')
    const { api } = this.rootStore
    runInAction(() => { this.loading = true; this.error = false })
    try {
      const prefs = await api.get('preferences')
      debug('prefs', prefs)
      runInAction(() => {
        this.loading = false
        Object.keys(prefs.notification || {}).map(context =>
          Object.keys(prefs.notification[context] || {}).map(action =>
            this.notification.get(context).set(action, (prefs.notification[context] || {})[action] || false)
          )
        )
      })
    } catch (e) {
      runInAction(() => {
        this.loading = false
        this.error = e
      })
    }
  }
}

debug('loaded')
