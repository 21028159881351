import { extendObservable } from 'mobx'
import { statusData, statusCategoryData, genieTypeData } from 'quantmatch-common'

const debug = require('debug')('qm:SearchStatusStore')

export default class SearchStatusStore {
  constructor (rootStore) {
    this.rootStore = rootStore
    extendObservable(this, {
      statusData: statusData,
      categoryData: statusCategoryData,
      genieTypeData: genieTypeData,
      get statuses () { return this.statusData.map(e => e.status) },
      get categories () { return [ ...(new Map(this.categoryData.map(e => [e.category, 1]))).keys() ] },
      get classes () { return [ ...(new Map(this.categoryData.map(e => [e.class, 1]))).keys() ] },
      get genieTypes () { return this.genieTypeData.map(t => t.type) },
      get categoryToDisplay () { return new Map(this.categoryData.map(e => [e.category, e.display])) },
      get categoryToClasses () {
        const map = new Map()
        this.categoryData.forEach(e =>
          map.set(e.category, (map.get(e.category) || []).concat([e.class]))
        )
        return map
      },
      get categoryToStatuses () {
        const map = new Map()
        this.statusData.forEach(e =>
          map.set(e.category, (map.get(e.category) || []).concat([e.status]))
        )
        return map
      },
      get classToStatuses () {
        const map = new Map()
        this.statusData.forEach(e =>
          map.set(e.class, (map.get(e.class) || []).concat([e.status]))
        )
        return map
      },
      get statusToCategory () { return new Map(this.statusData.map(e => [e.status, e.category])) },
      get statusToDisplay () { return new Map(this.statusData.map(e => [e.status, e.display])) },
      get displayToStatus () { return new Map(this.statusData.map(e => [e.display, e.status])) },
      get statusToClientVisible () { return new Map(this.statusData.map(e => [e.status, e.status === 'Potential' ? this.rootStore.auth.permissions.hasRole('Potential Candidates') : e.clientVisible])) },
      get statusToClientAllowedNext () { return new Map(this.statusData.map(e => [e.status, e.clientAllowedNext || []])) },
      get statusToClass () { return new Map(this.statusData.map(e => [e.status, e.class])) },
      get statusToSort () { return new Map(this.statusData.map((e, index) => [e.status, index])) },
      get genieTypeToDisplay () { return new Map(this.genieTypeData.map(e => [e.type, e.display])) },
      get genieTypeToClass () { return new Map(this.genieTypeData.map(e => [e.type, e.class])) }
    })
  }
}

debug('loaded')
