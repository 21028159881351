import { extendObservable, runInAction } from 'mobx'
import { companyNames } from 'quantmatch-common'
import { ObjectID } from 'bson'

const debug = require('debug')('qm:OrganizationStore')

export default class OrganizationStore {
  constructor (rootStore) {
    this.rootStore = rootStore
    extendObservable(this, {
      organizations: new Map(),
      error: null,
      loading: null,
      sort: 'created',
      get organizationsBySort () {
        const cmp = (a, b) => a[this.sort] > b[this.sort] ? -1 : a[this.sort] > b[this.sort] ? 1 : 0
        return this.organizations.values().sort(cmp)
      }
    })
  }

  get (organizationId) {
    return this.organizations.get(organizationId)
  }

  getWithDefault (organizationId, dfault = {}) {
    return this.organizations.get(organizationId) || Object.assign({}, dfault)
  }

  // Transform array of organizations into internal map format
  replace (organizations) {
    runInAction(() => {
      this.organizations.replace(organizations.map(u => [u.id, u]))
    })
  }

  // Add an array of organizations
  add (organizations = []) {
    debug('add', organizations)
    runInAction(() => {
      organizations.map(u => this.organizations.set(u.id, u))
    })
  }

  randomOrg () {
    const name = companyNames[Math.floor(Math.random() * companyNames.length)]
    const id = (new ObjectID()).toString()
    return { name, id }
  }

  randomize () {
    const orgs = (new Array(1000)).fill({}).map(this.randomOrg)
    debug('orgs', orgs)
    this.replace(orgs)
  }

  async fetchOwn () {
    const { api } = this.rootStore
    runInAction(() => { this.loading = true; this.error = false })
    try {
      const orgs = await api.get('organizations/own')
      runInAction(() => {
        this.loading = false
        this.add(orgs)
      })
    } catch (e) {
      runInAction(() => {
        this.loading = false
        this.error = e
      })
    }
  }

  async fetchOne (orgId) {
    const { api } = this.rootStore
    runInAction(() => { this.loading = true; this.error = false })
    try {
      const org = await api.get(`organizations/${orgId}`)
      runInAction(() => {
        this.loading = false
        this.add([org])
      })
    } catch (e) {
      runInAction(() => {
        this.loading = false
        this.error = e
      })
    }
  }

  async fetch () {
    const { api } = this.rootStore
    runInAction(() => { this.loading = true; this.error = false })
    try {
      const orgs = await api.get('organizations?limit=1000')
      runInAction(() => {
        this.loading = false
        this.replace(orgs)
      })
    } catch (e) {
      runInAction(() => {
        this.loading = false
        this.error = e
      })
    }
  }
}

debug('loaded')
