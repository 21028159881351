import { extendObservable, runInAction } from 'mobx'
import { Permissions } from 'quantmatch-common'

const debug = require('debug')('qm:UserStore')

export default class UserStore {
  constructor (rootStore) {
    this.rootStore = rootStore
    extendObservable(this, {
      users: new Map(),
      error: null,
      loading: null,
      sort: 'created',
      get usersBySort () {
        const cmp = (a, b) => a[this.sort] > b[this.sort] ? -1 : a[this.sort] > b[this.sort] ? 1 : 0
        return [...this.users.values()].sort(cmp)
      }
    })
  }

  get (userId) {
    const user = this.users.get(userId)
    // if (!user) throw new Error(`No such user ${userId}`)
    return user
  }

  // Transform array of users into internal map format
  replace (users) {
    runInAction(() => {
      this.users.replace(users.map(u => {
        u.lastError = null // need to initialize properties for observed changes to propagate
        u.pendingOrgRoles = new Map()
        u.hasPendingSearch = false
        u.hasPendingHiringManagerSearch = false
        return [u.sub, u]
      }))
    })
  }

  refreshSelf (userId) {
    const { auth, search } = this.rootStore
    if (auth.user && auth.user.sub === userId) {
      auth.refresh(_ => search.fetch())
    }
  }

  //
  // Specific search permissions
  //

  // Stores the fact that user wants to add search, but hasn't resolved it yet
  createPendingSearch (userId) {
    const user = this.get(userId)
    runInAction(() => { user.hasPendingSearch = true })
  }
  removePendingSearch (userId) {
    const user = this.get(userId)
    runInAction(() => { user.hasPendingSearch = false })
  }

  async addSearch (userId, searchId) {
    const user = this.get(userId)
    const permissions = new Permissions(user.permissions.toString())
    permissions.addSearch(searchId)

    runInAction(() => { this.loading = true; user.lastError = false; user.loading = true })
    try {
      const result = await this.rootStore.api.put(`user/${user.username}/permissions/search/${searchId}`)
      debug(result)
      runInAction(() => {
        debug({ permissions })
        this.loading = false
        user.loading = false
        this.users.get(userId)['custom:permissions'] = permissions.toString()
        this.users.get(userId).permissions = permissions
      })
    } catch (e) {
      debug(e)
      runInAction(() => {
        debug(e)
        this.loading = false
        user.loading = false
        user.lastError = e
      })
    }
    this.refreshSelf(userId)
  }

  async removeSearch (userId, searchId) {
    const user = this.get(userId)
    const permissions = new Permissions(user.permissions.toString())
    permissions.removeSearch(searchId)

    runInAction(() => { this.loading = true; user.lastError = false; user.loading = true })
    try {
      const result = await this.rootStore.api.delete(`user/${user.username}/permissions/search/${searchId}`)
      debug(result)
      runInAction(() => {
        debug({ permissions })
        this.loading = false
        user.loading = false
        this.users.get(userId)['custom:permissions'] = permissions.toString()
        this.users.get(userId).permissions = permissions
      })
    } catch (e) {
      debug(e)
      runInAction(() => {
        debug(e)
        this.loading = false
        user.loading = false
        user.lastError = e
      })
    }
    this.refreshSelf(userId)
  }

  //
  // All searches for specific hiring manager
  //

  // Stores the fact that user wants to add hiring manager search, but hasn't resolved it yet
  createPendingHiringManagerSearch (userId) {
    const user = this.get(userId)
    runInAction(() => { user.hasPendingHiringManagerSearch = true })
  }
  removePendingHiringManagerSearch (userId) {
    const user = this.get(userId)
    runInAction(() => { user.hasPendingHiringManagerSearch = false })
  }

  async addHiringManager (userId, personId) {
    const user = this.get(userId)
    const permissions = new Permissions(user.permissions.toString())
    permissions.addHiringManager(personId)

    runInAction(() => { this.loading = true; user.lastError = false; user.loading = true })
    try {
      const result = await this.rootStore.api.put(`user/${user.username}/permissions/hiringManager/${personId}`)
      debug(result)
      runInAction(() => {
        debug({ permissions })
        this.loading = false
        user.loading = false
        this.users.get(userId)['custom:permissions'] = permissions.toString()
        this.users.get(userId).permissions = permissions
      })
    } catch (e) {
      debug(e)
      runInAction(() => {
        debug(e)
        this.loading = false
        user.loading = false
        user.lastError = e
      })
    }
    this.refreshSelf(userId)
  }

  async removeHiringManager (userId, personId) {
    const user = this.get(userId)
    const permissions = new Permissions(user.permissions.toString())
    permissions.removeHiringManager(personId)

    runInAction(() => { this.loading = true; user.lastError = false; user.loading = true })
    try {
      const result = await this.rootStore.api.delete(`user/${user.username}/permissions/hiringManager/${personId}`)
      debug(result)
      runInAction(() => {
        debug({ permissions })
        this.loading = false
        user.loading = false
        this.users.get(userId)['custom:permissions'] = permissions.toString()
        this.users.get(userId).permissions = permissions
      })
    } catch (e) {
      debug(e)
      runInAction(() => {
        debug(e)
        this.loading = false
        user.loading = false
        user.lastError = e
      })
    }
    this.refreshSelf(userId)
  }

  //
  // Org Roles
  //

  // Stores the fact that user wants to add org role, but hasn't resolved organization yet
  createPendingOrgRole (userId, roleObject) {
    const user = this.get(userId)
    runInAction(() => {
      user.pendingOrgRoles.set(roleObject.name, roleObject)
    })
  }
  removePendingOrgRole (userId, roleObject) {
    const user = this.get(userId)
    runInAction(() => {
      user.pendingOrgRoles.delete(roleObject.name)
    })
  }

  async addOrgRole (userId, role, orgId) {
    const user = this.get(userId)
    const permissions = new Permissions(user.permissions.toString())
    permissions.addOrgRole(role, orgId)

    runInAction(() => { this.loading = true; user.lastError = false; user.loading = true })
    try {
      const result = await this.rootStore.api.put(`user/${user.username}/roles/org/${orgId}/${role}`)
      debug(result)
      runInAction(() => {
        debug({ permissions })
        this.loading = false
        user.loading = false
        this.users.get(userId)['custom:permissions'] = permissions.toString()
        this.users.get(userId).permissions = permissions
        // user.pendingOrgRoles.delete(role)
      })
    } catch (e) {
      debug(e)
      runInAction(() => {
        debug(e)
        this.loading = false
        user.loading = false
        user.lastError = e
      })
    }
    this.refreshSelf(userId)
  }

  async removeOrgRole (userId, role, orgId) {
    const user = this.get(userId)
    const permissions = new Permissions(user.permissions.toString())
    permissions.removeOrgRole(role, orgId)

    runInAction(() => { this.loading = true; user.lastError = false; user.loading = true })
    try {
      const result = await this.rootStore.api.delete(`user/${user.username}/roles/org/${orgId}/${role}`)
      debug(result)
      runInAction(() => {
        debug({ permissions })
        this.loading = false
        user.loading = false
        debug('permissions', permissions)
        this.users.get(userId)['custom:permissions'] = permissions.toString()
        this.users.get(userId).permissions = permissions
      })
    } catch (e) {
      debug(e)
      runInAction(() => {
        this.loading = false
        user.loading = false
        user.lastError = e
      })
    }
    this.refreshSelf(userId)
  }

  //
  // Global roles
  //

  async addGlobalRole (userId, role) {
    const user = this.get(userId)
    const permissions = new Permissions(user.permissions.toString())
    permissions.addGlobalRole(role)

    runInAction(() => { this.loading = true; user.lastError = false; user.loading = true })
    try {
      const result = await this.rootStore.api.put(`user/${user.username}/roles/global/${role}`)
      debug(result)
      runInAction(() => {
        debug({ permissions })
        this.loading = false
        user.loading = false
        this.users.get(userId)['custom:permissions'] = permissions.toString()
        this.users.get(userId).permissions = permissions
      })
    } catch (e) {
      debug(e)
      runInAction(() => {
        debug(e)
        this.loading = false
        user.loading = false
        user.lastError = e
        // this.users.set(userId, user)
      })
    }
    this.refreshSelf(userId)
  }

  async removeGlobalRole (userId, role) {
    const user = this.get(userId)
    debug('BLARG user.permissions', user.permissions)
    const permissions = new Permissions(user.permissions.toString())
    permissions.removeGlobalRole(role)

    runInAction(() => { this.loading = true; user.lastError = false; user.loading = true })
    try {
      const result = await this.rootStore.api.delete(`user/${user.username}/roles/global/${role}`)
      debug(result)
      runInAction(() => {
        debug({ permissions })
        this.loading = false
        user.loading = false
        debug('permissions', permissions)
        this.users.get(userId)['custom:permissions'] = permissions.toString()
        this.users.get(userId).permissions = permissions
      })
    } catch (e) {
      debug(e)
      runInAction(() => {
        this.loading = false
        user.loading = false
        user.lastError = e
        // this.users.set(userId, user)
      })
    }
    this.refreshSelf(userId)
  }

  async setUserPassword (userId, Password, Permanent) {
    const user = this.get(userId)
    runInAction(() => { this.loading = true; user.lastError = false; user.loading = true })
    try {
      const params = { Password, Permanent }
      const result = await this.rootStore.api.put(`user/${user.username}/password`, params)
      debug(result)
      runInAction(() => {
        this.loading = false
        user.loading = false
      })
    } catch (e) {
      debug(e)
      runInAction(() => {
        this.loading = false
        user.loading = false
        user.lastError = e
        // this.users.set(userId, user)
      })
      throw e
    }
    this.refreshSelf(userId)
  }

  async fetch () {
    const { api, search } = this.rootStore
    runInAction(() => { this.loading = true; this.error = false })
    try {
      const { users, orgs, searches, people } = await api.get('users');
      (searches || []).forEach(s => { s.hidden = (search.get(s.id) || { hidden: true }).hidden })
      if (searches) this.rootStore.search.add(searches)
      if (orgs) this.rootStore.organization.add(orgs)
      if (people) this.rootStore.person.add(people)
      runInAction(() => {
        users.forEach(user => {
          user.permissions = new Permissions(user['custom:permissions'] || {})
        })
        this.loading = false
        this.replace(users)
      })
    } catch (e) {
      runInAction(() => {
        this.loading = false
        this.error = e
      })
    }
  }
}

debug('loaded')
