"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.overlap = overlap;
exports.union = union;
exports.intersection = intersection;

const debug = require('debug')('qm:intervals'); //
// Interval Utilities
//
// All function operate on intervals in the form {start: Number, end: Number}
// and expect that start is less than end.
// Intervals are endpoint inclusive, i.e. [A, B].
//
//
// This function returns true if the given intervals overlap, I.E. there is
// a number which falls inside both intervals.
//
// @A interval in the form {start: Number, end: Number}
// @B interval in the form {start: Number, end: Number}
// returns true if intervals overlap, false if not
//


function overlap(a, b) {
  return a.end >= b.start && a.start <= b.end;
} //
// This function flattens a list of potentially overlapping intervals
// into a potentially shorter list of the union of those intervals.
//
// @intervals Array of intervals in the format {start: Number, end: Number}
// returns an Array of intervals in the format {start: Number, end: Number}
//


function union(intervals) {
  const sorted = intervals.slice().sort((a, b) => a.start - b.start || a.end - b.end);
  const results = [];
  let current = sorted.length ? Object.assign({}, sorted[0]) : undefined; // let current = {start: Number.MAX_VALUE, end: Number.MIN_VALUE}
  // Loop through sorted set

  for (let i = 0; i < sorted.length; i++) {
    const next = Object.assign({}, sorted[i]);
    debug({
      next
    });

    if (overlap(next, current)) {
      // Grow the current interval by the new one
      current.start = Math.min(next.start, current.start);
      current.end = Math.max(next.end, current.end);
      debug({
        overlap: true,
        current
      });
    } else {
      // Add current interval to our results and try to grow it
      results.push(current);
      current = next;
      debug({
        overlap: false,
        current,
        results
      });
    }
  }

  if (current) results.push(current);
  debug({
    results
  });
  return results;
} //
// This function finds the intersection of two lists of intervals, returning
// a potentially shorter list of intervals describing their overlap.
//
// @A Array of intervals in the format {start: Number, end: Number}
// @B Array of intervals in the format {start: Number, end: Number}
// returns an Array of intervals in the format {start: Number, end: Number}
//


function intersection(A, B) {
  const sortedA = union(A).slice().sort((a, b) => b.start - b.start || a.end - b.end);
  const sortedB = union(B).slice().sort((a, b) => b.start - b.start || a.end - b.end);
  const results = []; // Naive algorithm: O(n^2)

  for (let i = 0; i < sortedA.length; i++) {
    const a = Object.assign({}, sortedA[i]);

    for (let j = 0; j < sortedB.length; j++) {
      const b = Object.assign({}, sortedB[j]);

      if (overlap(a, b)) {
        const c = {
          start: Math.max(a.start, b.start),
          end: Math.min(a.end, b.end)
        };
        results.push(c);
      }
    }
  }

  return union(results);
}

debug('loaded');