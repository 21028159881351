/* global window */
import { extendObservable, runInAction, autorun } from 'mobx'
import { createTransformer } from 'mobx-utils'
import { jobTitles, tokenize } from 'quantmatch-common'
import { ObjectID } from 'bson'
import moment from 'moment'
import querystring from 'querystring'
window.querystring = querystring

const debug = require('debug')('qm:SearchStore')

export default class SearchStore {
  constructor (rootStore) {
    this.rootStore = rootStore
    const { searchStatus } = this.rootStore
    const q = querystring.parse(window.location.search).q || ''
    const orgQ = querystring.parse(window.location.search).orgQ || ''
    const peopleQ = querystring.parse(window.location.search).peopleQ || ''
    extendObservable(this, {
      searches: new Map(),
      q,
      qNgrams: tokenize(q),
      orgQ,
      orgNgrams: tokenize(orgQ),
      peopleQ,
      peopleNgrams: tokenize(peopleQ),
      error: null,
      loading: null,
      showLoading: null,
      firstLoad: true,
      fullScreen: window.localStorage && window.localStorage.getItem('fullScreen') === 'true',
      sortBy: 'status',
      renderMode: window.localStorage && window.localStorage.getItem('debugMode') === 'true' ? 'None' : 'Virtualized Rows and Columns',
      debug: window.localStorage && window.localStorage.getItem('debugMode') === 'true',
      adminMode: window.localStorage && window.localStorage.getItem('adminMode') === 'true',
      showClosed: window.localStorage && window.localStorage.getItem('showClosed') === 'true',
      autoRefresh: window.localStorage && window.localStorage.getItem('autoRefresh') !== 'false',
      showFlaggedOnly: false,
      searchSort: 'company',
      originalSearchSort: 'company',
      searchCount: 15,
      candidateCount: 15,
      replaceCount: 0,
      renderModes: {
        none: 'None',
        plain: 'Plain',
        vRow: 'Virtualized Rows',
        vRowCol: 'Virtualized Rows and Columns'
      },
      filterBy: new Map(searchStatus.statuses.map(s => [s, true])),
      // filterBy: new Map(),
      get filterByUnique () { return [...this.filterBy.entries()].map(JSON.stringify).join() },
      get sortedSearches () {
        // debug('sortedSearches is being recalculated', [...this.searches.entries()])
        return new Map(
          [...this.searches.entries()]
            .filter(([, search]) => {
              // debug('filter search', search)
              return true
            })
            .filter(([, search]) => {
              // debug({'this.showFlaggedOnly': this.showFlaggedOnly})
              return this.showFlaggedOnly
                ? [...search.candidates.entries()].some(([, candidate]) => candidate.priorityFlag)
                : true
            }
            )
            .filter(([, search]) => !search.hidden)
            // .filter(([, search]) =>
            //   this.peopleQ
            //     ? [...search.candidates.entries()].some(([, candidate]) => {
            //       const person = this.rootStore.person.get(candidate.personId)
            //       return person && (
            //         person.givenName.toLowerCase().indexOf(this.peopleQ.toLowerCase()) !== -1 ||
            //         person.familyName.toLowerCase().indexOf(this.peopleQ.toLowerCase()) !== -1
            //       )
            //     })
            //     : true
            // )
            .filter(([, search]) =>
              this.peopleQ
                ? [...search.candidates.entries()].some(([, candidate]) => {
                  const person = this.rootStore.person.get(candidate.personId)
                  return person && this.peopleNgrams.map(gram =>
                    // Startswith
                    person.givenName.toLowerCase().indexOf(gram) === 0 || person.familyName.toLowerCase().indexOf(gram) === 0
                  ).reduce((a, b) => a + b, 0) > 0
                })
                : true
            )
            .sort((A, B) => {
              var a, b
              switch (this.searchSort) {
                /* Remove for performance, sort wasn't really working anyway
                case 'score':
                  if (this.peopleQ) {
                    a = Math.max(...[...A[1].candidates]
                      .map(([, candidate]) => {
                        const person = this.rootStore.person.get(candidate.personId)
                        return person
                          ? this.peopleNgrams
                            .map(gram =>
                              (person.givenName.indexOf(gram) !== -1) + (person.familyName.indexOf(gram) !== -1)
                            )
                            .reduce((a, b) => a + b, 0)
                          : 0
                      }))
                    b = Math.max(...[...B[1].candidates]
                      .map(([, candidate]) => {
                        const person = this.rootStore.person.get(candidate.personId)
                        return person
                          ? this.peopleNgrams
                            .map(gram =>
                              (person.givenName.indexOf(gram) !== -1) + (person.familyName.indexOf(gram) !== -1)
                            )
                            .reduce((a, b) => a + b, 0)
                          : 0
                      }))
                    debug('score sort', {a, b, aid: A[0], bid: B[0]})
                    return a === b ? B[1].score - A[1].score : b - a
                  }
                  a = A[1].score
                  b = B[1].score
                  return a === b ? 0 : a > b ? -1 : 1
                  */
                case 'company':
                  a = (this.rootStore.organization.get(A[1].orgId) || { name: '' }).name + A[1].name
                  b = (this.rootStore.organization.get(B[1].orgId) || { mame: '' }).name + B[1].name
                  return a === b ? 0 : a > b ? 1 : -1
                case 'opened':
                  a = A[1].created
                  b = B[1].created
                  return a === b ? 0 : (new Date(a)) > (new Date(b)) ? -1 : 1
                case 'candidates':
                  a = this.candidatesForReport(A[0]).size
                  b = this.candidatesForReport(B[0]).size
                  return a === b ? 0 : a > b ? -1 : 1
                default:
                  return 0
              }
            })
            .map(([oldIndex, search], newIndex) => [newIndex, search]))
      },
      candidateCountByCategory: createTransformer(category =>
        [...this.searches.values()].map(search =>
          [...search.candidates.values()]
            .filter(candidate => this.showFlaggedOnly ? candidate.priorityFlag : true)
            .filter(candidate => {
              if (!this.peopleQ) return true
              const person = this.rootStore.person.get(candidate.personId)
              if (!person) return false
              return this.peopleNgrams.map(gram =>
                (person.givenName.indexOf(gram) !== -1) + (person.familyName.indexOf(gram) !== -1)
              ).reduce((a, b) => a + b, 0) > 0
            })
            .filter(candidate =>
              searchStatus.statusToClientVisible.get(candidate.status) &&
                this.rootStore.searchStatus.statusToCategory.get(candidate.status) === category
            ).length
        ).reduce((a, b) => a + b, 0)
      ),
      sortedCandidates: createTransformer(searchId => {
        return new Map([...this.searches.get(searchId).candidates.entries()]
          .filter(pair => Array.isArray(pair))
          .filter(([, candidate]) => this.showFlaggedOnly ? candidate.priorityFlag : true)
          .filter(([, candidate]) => {
            if (!this.peopleQ) return true
            const person = this.rootStore.person.get(candidate.personId)
            if (!person) return false
            return this.peopleNgrams.map(gram =>
              (person.givenName.toLowerCase().indexOf(gram) !== -1) + (person.familyName.toLowerCase().indexOf(gram) !== -1)
            ).reduce((a, b) => a + b, 0) > 0
          })
          .filter(([, candidate]) => this.filterBy.size > 0
            ? this.filterBy.get(candidate.status)
            : false)
          .filter(([, candidate]) => searchStatus.statusToClientVisible.get(candidate.status))
          .sort(([, a], [, b]) => {
            if (this.peopleQ) {
              const A = this.rootStore.person.get(a.personId)
              const B = this.rootStore.person.get(b.personId)
              const Ascore = A
                ? this.peopleNgrams.map(gram =>
                  (A.givenName.indexOf(gram) !== -1) + (A.familyName.indexOf(gram) !== -1)
                ).reduce((a, b) => a + b, 0)
                : 0
              const Bscore = B
                ? this.peopleNgrams.map(gram =>
                  (B.givenName.indexOf(gram) !== -1) + (B.familyName.indexOf(gram) !== -1)
                ).reduce((a, b) => a + b, 0)
                : 0
              return Bscore - Ascore
            }
            if (['added', 'updated'].includes(this.sortBy)) {
              return a[this.sortBy] > b[this.sortBy] ? -1 : 1
            }
            if (this.sortBy === 'status') {
              var sA = searchStatus.statusToSort.get(a.colorStatus)
              var sB = searchStatus.statusToSort.get(b.colorStatus)
              if (sA === sB) return a.updated > b.updated ? -1 : 1
              return sA < sB ? -1 : 1
            }
            if (this.sortBy === 'name') {
              const aname = (rootStore.person.get(a.personId).familyName + rootStore.person.get(a.personId).givenName).toLowerCase()
              const bname = (rootStore.person.get(b.personId).familyName + rootStore.person.get(b.personId).givenName).toLowerCase()
              return aname < bname ? -1 : 1
            }
            return 0
          })
          .map(([oldIndex, candidate], newIndex) => [newIndex, candidate]))
      }),
      candidatesById: createTransformer(searchId => {
        return new Map(
          [...(this.searches.get(searchId) || {candidates: new Map()}).candidates.entries()]
            .map(([oldIndex, candidate], newIndex) => [candidate.personId, candidate])
        )
      }),
      candidatesForReport: createTransformer(searchId => {
        return new Map([...this.searches.get(searchId).candidates.entries()]
          .filter(pair => Array.isArray(pair))
          .filter(([, candidate]) => searchStatus.statusToClientVisible.get(candidate.status))
          .sort(([, a], [, b]) =>
            searchStatus.statusToSort.get(a.status) < searchStatus.statusToSort.get(b.status) ? -1 : 1
          )
          .map(([oldIndex, candidate], newIndex) => [newIndex, candidate]))
      }),
      searchesByPerson: createTransformer(personId => {
        return new Map([...this.searches.entries()]
          .filter(pair => Array.isArray(pair))
          .filter(([, search]) =>
            // Figure out candidate is in this search
            [...search.candidates.entries()]
              .filter(pair => Array.isArray(pair))
              .some(([, candidate]) => candidate.personId === personId)
          )
        )
      })
    })
    this.changeSortBy = this.changeSortBy.bind(this)
    this.toggleDebug = this.toggleDebug.bind(this)
    this.toggleFullScreen = this.toggleFullScreen.bind(this)
    this.toggleShowClosed = this.toggleShowClosed.bind(this)
    this.toggleAutoRefresh = this.toggleAutoRefresh.bind(this)
    autorun(() => debug('renderMode', this.renderMode))
    autorun(() => debug('sortBy', this.sortBy))
    autorun(() => debug('replaceCount', this.replaceCount))
    autorun(() => debug('filterBy', this.filterBy))
    autorun(() => debug('filterByUnique', this.filterByUnique))
    if (this.autoRefresh) this.startRefreshing()
    else this.stopRefreshing()
  }

  get (k) { return this.searches.get(k) }
  has (k) { return this.searches.has(k) }

  updateSearch (q, location, history) {
    runInAction(() => {
      this.q = q
      this.qNgrams = tokenize(q || '')
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(_ => {
        if (history) {
          const query = querystring.parse(window.location.search.replace('?', ''))
          debug('query', query)
          query.q = this.q
          query.peopleQ = this.peopleQ
          query.orgQ = this.orgQ
          if (!query.q) delete query.q
          if (!query.peopleQ) delete query.peopleQ
          if (!query.orgQ) delete query.orgQ
          history.push({ pathname: '/', search: '?' + querystring.stringify(query) })
        }
        this.fetch()
      }, 5)
    })
  }

  updatePeopleSearch (peopleQ, location, history) {
    const originalPeopleQ = this.peopleQ.toString()
    runInAction(() => {
      this.peopleQ = peopleQ
      this.peopleNgrams = tokenize(peopleQ || '')
      if (history) {
        const query = querystring.parse(window.location.search.replace('?', ''))
        debug('query', query)
        query.q = this.q
        query.peopleQ = this.peopleQ
        query.orgQ = this.orgQ
        if (!query.q) delete query.q
        if (!query.peopleQ) delete query.peopleQ
        if (!query.orgQ) delete query.orgQ
        history.push({ pathname: '/', search: '?' + querystring.stringify(query) })
      }
      this.fetch()
      if (!originalPeopleQ) {
        this.changeSearchSort('score')
      }
    })
  }

  updateOrgSearch (orgQ, location, history) {
    // const originalOrgQ = this.orgQ.toString()
    runInAction(() => {
      this.orgQ = orgQ
      this.orgNgrams = tokenize(orgQ || '')
      if (history) {
        const query = querystring.parse(window.location.search.replace('?', ''))
        debug('query', query)
        query.q = this.q
        query.peopleQ = this.peopleQ
        query.orgQ = this.orgQ
        if (!query.q) delete query.q
        if (!query.peopleQ) delete query.peopleQ
        if (!query.orgQ) delete query.orgQ
        history.push({ pathname: '/', search: '?' + querystring.stringify(query) })
      }
      this.fetch()
    })
  }

  toggleFullScreen () {
    runInAction(() => {
      this.fullScreen = !this.fullScreen
      window.localStorage && this.fullScreen
        ? window.localStorage.setItem('fullScreen', 'true')
        : window.localStorage.removeItem('fullScreen')
    })
  }
  toggleDebug () {
    runInAction(() => {
      this.debug = !this.debug
      window.localStorage && this.debug
        ? window.localStorage.setItem('debugMode', 'true')
        : window.localStorage.removeItem('debugMode')
    })
  }
  async toggleAdminMode () {
    runInAction(() => {
      this.adminMode = !this.adminMode
      window.localStorage && this.adminMode
        ? window.localStorage.setItem('adminMode', 'true')
        : window.localStorage.removeItem('adminMode')
    })
    await this.fetch()
  }
  toggleShowClosed () {
    runInAction(() => {
      this.showClosed = !this.showClosed
      window.localStorage && this.showClosed
        ? window.localStorage.setItem('showClosed', 'true')
        : window.localStorage.removeItem('showClosed')
    })
    this.fetch()
  }
  toggleAutoRefresh () {
    runInAction(() => {
      this.autoRefresh = !this.autoRefresh
      window.localStorage && this.autoRefresh
        ? window.localStorage.setItem('autoRefresh', 'true')
        : window.localStorage.setItem('autoRefresh', 'false')
    })
    if (this.autoRefresh) this.startRefreshing()
    else this.stopRefreshing()
  }
  toggleFlaggedOnly () {
    runInAction(() => {
      this.showFlaggedOnly = !this.showFlaggedOnly
      window.localStorage && this.showFlaggedOnly
        ? window.localStorage.setItem('showFlaggedOnly', 'true')
        : window.localStorage.removeItem('showFlaggedOnly')
    })
  }
  setSearchCount (searchCount) { runInAction(() => { this.searchCount = parseInt(searchCount, 10); this.randomize() }) }
  setCandidateCount (candidateCount) { runInAction(() => { this.candidateCount = parseInt(candidateCount, 10); this.randomize() }) }
  setRenderMode (renderMode) { runInAction(() => { this.renderMode = renderMode }) }

  async togglePriorityFlag (searchId, candidateId) {
    runInAction(() => {
      this.candidatesById(searchId).get(candidateId).priorityFlag = !this.candidatesById(searchId).get(candidateId).priorityFlag
    })
    const flagUrl = `searches/${searchId}/${candidateId}/priorityFlag`
    const { api } = this.rootStore
    if (this.candidatesById(searchId).get(candidateId).priorityFlag) {
      await api.put(flagUrl)
    } else {
      await api.delete(flagUrl)
    }
  }

  toggleRedDot (searchId, candidateId) {
    runInAction(() => {
      this.candidatesById(searchId).get(candidateId).redDot = !this.candidatesById(searchId).get(candidateId).redDot
    })
  }

  changeSortBy (newSortBy) {
    runInAction(() => {
      const allowed = ['added', 'updated', 'status', 'name']
      if (allowed.includes(newSortBy)) {
        this.sortBy = newSortBy
      } else {
        throw new Error(`Invalid sortBy "${newSortBy}". Allowed values: ${allowed.join(' ')}`)
      }
    })
  }

  changeSearchSort (newSort) {
    runInAction(() => {
      const allowed = ['score', 'company', 'opened', 'candidates']
      if (allowed.includes(newSort)) {
        this.searchSort = newSort
        this.originalSearchSort = newSort
      } else {
        throw new Error(`Invalid sortBy "${newSort}". Allowed values: ${allowed.join(' ')}`)
      }
    })
  }

  /* @newFilterBy can be a category or a status */
  toggleFilterBy (newFilterBy) {
    const { searchStatus } = this.rootStore
    const isCategory = searchStatus.categories.indexOf(newFilterBy) !== -1
    const isClass = searchStatus.classes.indexOf(newFilterBy) !== -1

    // convert to raw statuses and store a map of them
    const statuses = isCategory
      ? searchStatus.categoryToStatuses.get(newFilterBy)
      : isClass
        ? searchStatus.classToStatuses.get(newFilterBy)
        : [newFilterBy]

    // Categories should remove any mucking we've done with individual check boxes
    if (isCategory) {
      const shouldRemove = statuses.every(status => this.filterBy.get(status))
      return runInAction(() => {
        statuses.forEach(status => {
          if (shouldRemove) this.filterBy.set(status, false)
          else this.filterBy.set(status, true)
        })
      })
    }

    // Classes should remove any mucking we've done with individual check boxes
    if (isClass) {
      const shouldRemove = statuses.every(status => this.filterBy.get(status))
      return runInAction(() => {
        statuses.forEach(status => {
          if (shouldRemove) this.filterBy.set(status, false)
          else this.filterBy.set(status, true)
        })
      })
    }

    // Otherwise, toggle individual status
    if (statuses.length) {
      runInAction(() => {
        statuses.forEach(status => {
          if (this.filterBy.get(status)) this.filterBy.set(status, false)
          else this.filterBy.set(status, true)
        })
      })
    }
  }

  replace (searches) {
    runInAction(() => {
      const newMap = searches.map(s => [s.id, s])
      searches.forEach(s => {
        s.candidates = new Map((s.candidates || []).map(c => [c.personId, c]))
        s.hidden = s.hidden || false
      })
      this.searches.replace(newMap)
      this.replaceCount = this.replaceCount + 1
    })
  }

  add (searches) {
    runInAction(() => {
      searches.forEach(s => {
        s.candidates = new Map((s.candidates || []).map(c => [c.personId, c]))
        s.hidden = s.hidden ? s.hidden : false
        this.searches.set(s.id, s)
      })
    })
  }

  startRefreshing () {
    clearTimeout(this.refreshTimeout)
    this.refreshTimeout = setTimeout(async _ => {
      try {
        await this.fetch(false)
      } catch (e) {
        debug('fetch error', e)
      }
      this.startRefreshing()
    }, 30 * 1000)
  }

  stopRefreshing () {
    clearTimeout(this.refreshTimeout)
  }

  async fetch (showLoading = true) {
    const { api, organization, person } = this.rootStore
    if (this.loading) return
    const originalQ = this.q
    runInAction(() => {
      this.loading = true
      this.showLoading = showLoading
      this.error = false
    })
    try {
      const params = {
        limit: 100,
        includeOrgs: true,
        includePeople: true
      }
      if (this.q) params.name = this.q
      if (this.orgQ) params.orgName = this.orgQ
      if (this.peopleQ) params.candidateName = this.peopleQ
      if (this.adminMode) params.adminMode = true
      if (this.showClosed) params.showClosed = true
      // const qs = '?limit=100&includeOrgs=true&includePeople=true' + (this.q ? '&q=' + encodeURIComponent(this.q) : '') + (this.adminMode ? '&adminMode=true' : '') + (this.showClosed ? '&showClosed=true' : '')
      const qs = '?' + querystring.stringify(params)
      const { searches, orgs, people } = await api.get('searches' + qs)
      debug('returned', { searches, orgs, people })
      // searches.forEach(search => {
      //   // search.candidates = this.randomCandidates(search.created)
      //   debug('candidates before', search.candidates)
      //   search.candidates = new Map((search.candidates || []).map(c => [c.personId, c]))
      //   debug('candidates after', search.candidates)
      // })
      debug('searches', searches)
      organization.add(orgs)
      person.add(people)
      debug('after')
      this.searches.forEach(s => { s.hidden = true })
      // this.add(searches)
      this.replace(searches)
      runInAction(() => {
        this.loading = false
        this.firstLoad = false
        this.showLoading = false
        this.searchSort = originalQ ? 'score' : this.originalSearchSort
      })
      if (this.q !== originalQ) this.fetch()
    } catch (e) {
      debug(e)
      runInAction(() => {
        this.loading = false
        this.firstLoad = false
        this.showLoading = false
        this.error = e
      })
    }
  }

  async fetchOne (searchId) {
    const { api, organization, person } = this.rootStore
    if (this.loading) return
    runInAction(() => {
      this.loading = true
      this.showLoading = true
      this.error = false
    })
    try {
      const qs = '?includeOrgs=true&includePeople=true' + (this.q ? '&q=' + encodeURIComponent(this.q) : '') + (this.adminMode ? '&adminMode=true' : '') + (this.showClosed ? '&showClosed=true' : '')
      const { search, orgs, people } = await api.get(`searches/${searchId}` + qs)
      debug('returned', { search, orgs, people })
      debug('search', search)
      organization.add(orgs)
      person.add(people)
      debug('after')
      this.add([search])
      runInAction(() => {
        this.loading = false
        this.firstLoad = false
        this.showLoading = false
      })
    } catch (e) {
      debug(e)
      runInAction(() => {
        this.loading = false
        this.firstLoad = false
        this.showLoading = false
        this.error = e
      })
    }
  }

  async post (search) {
    const { api } = this.rootStore
    if (this.loading) return
    runInAction(() => {
      this.loading = true
      this.showLoading = true
      this.error = false
    })
    try {
      const created = await api.post('searches', search)
      debug('returned', created)
      this.add([created])
      runInAction(() => {
        this.loading = false
        this.firstLoad = false
        this.showLoading = false
      })
    } catch (e) {
      debug(e)
      runInAction(() => {
        this.loading = false
        this.firstLoad = false
        this.showLoading = false
        this.error = e
      })
    }
  }

  randomCandidates (created) {
    const { person, searchStatus } = this.rootStore
    const candidates = new Map((new Array(this.candidateCount)).fill({}).map((candidate, candidateIndex) => {
      const id = (new ObjectID()).toString()
      const personId = person.people.values()[Math.floor(Math.random() * person.people.size)].id
      const added = moment(moment.max(created, moment().subtract(Math.random() * 365, 'days'))).toDate()
      const _updated = moment(moment.max(added, moment().subtract(Math.random() * 100, 'days'))).toDate()
      const status = searchStatus.statuses[Math.floor(Math.random() * searchStatus.statuses.length)]
      const priorityFlag = Math.random() > 0.9
      const redDot = Math.random() > 0.9
      return [candidateIndex, { id, personId, added, updated: _updated, status, priorityFlag, redDot }]
    }))
    return candidates
  }

  randomize () {
    const { organization } = this.rootStore
    const searches = (new Array(this.searchCount)).fill({}).map(_ => {
      const id = (new ObjectID()).toString()
      const name = jobTitles[Math.floor(Math.random() * jobTitles.length)]
      const notes = `Search ${id.slice(-6)} is a very nice search.`
      const orgId = organization.organizations.values()[Math.floor(Math.random() * organization.organizations.size)].id
      const created = moment().subtract(Math.random() * 365, 'days').toDate()
      const updated = moment(moment.max(created, moment().subtract(Math.random() * 100, 'days'))).toDate()
      const candidates = this.randomCandidates(created)
      return { id, name, notes, orgId, created, updated, candidates }
    })
    debug('randomize', searches)
    this.replace(searches)
  }
}

debug('loaded')
