import { extendObservable, runInAction } from 'mobx'
import querystring from 'querystring'

const debug = require('debug')('qm:ReadOnlySearchStore')

//
// This store is intended for searches that are not show on the main UI
// For example, managing search permissions.
//

export default class ReadOnlySearchStore {
  constructor (rootStore) {
    this.rootStore = rootStore
    extendObservable(this, {
      searches: new Map(),
      orgIds: [],
      error: null,
      loading: null
    })
  }

  get (k) { return this.searches.get(k) }
  has (k) { return this.searches.has(k) }

  replace (searches) {
    runInAction(() => {
      debug('hi')
      const newMap = searches.map(s => [s.id, s])
      debug('newMap', { newMap })
      searches.forEach(s => {
        s.candidates = new Map((s.candidates || []).map(c => [c.personId, c]))
      })
      this.searches.replace(newMap)
      this.replaceCount = this.replaceCount + 1
    })
    debug('searches', this.searches)
  }

  add (searches) {
    runInAction(() => {
      searches.forEach(s => {
        s.candidates = new Map((s.candidates || []).map(c => [c.personId, c]))
        this.searches.set(s.id, s)
      })
    })
  }

  async fetch () {
    const { api, search } = this.rootStore
    if (this.loading) return
    runInAction(() => {
      this.loading = true
      this.error = false
    })
    try {
      const query = {
        limit: 1000,
        orgId: this.orgIds
      }
      if (search.adminMode) query.adminMode = true
      const q = querystring.encode(query)
      const { searches } = await api.get('searches?' + q)
      debug('returned', { searches })
      debug('after')
      this.replace(searches)
      runInAction(() => {
        this.loading = false
      })
    } catch (e) {
      debug(e)
      runInAction(() => {
        this.loading = false
        this.error = e
      })
    }
  }
}

debug('loaded')
