/* global serverConfig */

import { extendObservable, runInAction, autorun } from 'mobx'
import { createTransformer } from 'mobx-utils'
import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import jwtDecode from 'jwt-decode'
import querystring from 'querystring'
import { Permissions } from 'quantmatch-common'
import store from 'store'

const debug = require('debug')('qm:AuthStore')
const storage = store.namespace('qm_AuthStore')
window.authStorage = storage

if (!window.serverConfig) {
  window.serverConfig = {
    cognitoUserPoolId: 'us-east-1_tvFLrZ88D',
    cognitoClientId: '52fnqc5hikrqu5q4rjtti584op'
  }
}
const UserPoolId = serverConfig.cognitoUserPoolId
const ClientId = serverConfig.cognitoClientId

export default class AuthStore {
  constructor (rootStore) {
    this.rootStore = rootStore
    this.userPool = window.userPool = new CognitoUserPool({ UserPoolId, ClientId })

    // The temporaryUsername serves to persist the username before a login is
    // complated. This is useful between registration and confirmation and
    // during password resets. Even if the user navigates away or follows an
    // email link, the username is stored until login
    const temporaryUsername = storage.get('temporaryUsername')
    // Use temporaryUsername if we have one, otherwise, try the cognito sdk's persisted user
    const cognitoUser = temporaryUsername
      ? new CognitoUser({ Username: temporaryUsername, Pool: this.userPool })
      : this.userPool.getCurrentUser()
    debug({ temporaryUsername })

    // The lastPreLoginAction variable tracks whether we were confirming or
    // resetting password. This is so email links (which don't differentiate)
    // can be routed to the correct view when user clicks them
    const lastPreLoginAction = storage.get('lastPreLoginAction')

    extendObservable(this, {
      cognitoUser,
      lastPreLoginAction,
      token: null,
      state: 'BOOTING',
      states: {
        booting: 'BOOTING',
        confirming: 'CONFIRMING',
        loggedOut: 'LOGGED_OUT',
        loggedIn: 'LOGGED_IN',
        resetPassword: 'RESET_PASSWORD',
        newPasswordRequired: 'NEW_PASSWORD_REQUIRED'
      },
      user: null,
      permissions: null,
      // Creates a linked with an embedded authToken in the querystring.
      // If you need to include your own querystring, pass an object in
      // the @query parameter.
      tokenLink: createTransformer((path, query = {}) => {
        const q = querystring.encode(Object.assign(query, { authToken: this.token }))
        return this.rootStore.api.baseURL + path + (q ? `?${q}` : '')
      }),
      get isAdmin () {
        return this.permissions && this.permissions.hasGlobalRole('Admin')
      },
      get defaultSearch () {
        return this.user ? this.user['custom:defaultSearch'] : undefined
      },
      attributeStash: null
    })
    autorun(() => debug('state', this.state, 'cognitoUser', this.cognitoUser))
  }

  setUsername (email) {
    // Normalize email as used for username
    const username = (email || '').toLowerCase()
    runInAction(() => {
      this.cognitoUser = new CognitoUser({ Username: username, Pool: this.userPool })
    })
  }

  startRefreshing () {
    clearTimeout(this.refreshTimeout)
    this.refreshTimeout = setTimeout(async _ => {
      const now = Date.now() / 1000
      // Refresh if token expires within one minute
      debug('checking refresh', { idExpiration: this.idExpiration, now })
      if ((this.idExpiration + 60) < now) {
        debug('auto-refreshing token')
        try {
          await new Promise((resolve, reject) => {
            this.refresh((err, data) => {
              if (err) reject(err)
              resolve(data)
            })
          })
        } catch (e) {
          debug('refresh error', e)
        }
      }
      this.startRefreshing()
    }, 30 * 1000)
  }

  stopRefreshing () {
    clearTimeout(this.refreshTimeout)
  }

  init (callback = _ => {}) {
    if (this.cognitoUser) {
      this.cognitoUser.getSession((err, session) => {
        debug('user.getSession(err, session)', err, session)
        if (err) {
          runInAction(() => {
            this.state = this.states.loggedOut
          })
          return callback(err)
        }
        debug('session.isValid()', session.isValid())
        runInAction(() => {
          this.user = jwtDecode(session.idToken.jwtToken)
          this.idExpiration = session.idToken.payload.exp
          if (session.isValid()) {
            this.token = session.idToken.jwtToken
            this.state = this.states.loggedIn
            this.permissions = new Permissions(this.user['custom:permissions'] || '')
          } else {
            this.token = null
            this.state = this.states.loggedOut
            this.permissions = null
            this.stopRefreshing()
          }
        })
        callback()
      })
    } else {
      runInAction(() => {
        this.state = this.states.loggedOut
      })
      callback(new Error('never authenticated'))
    }
  }

  refresh (callback = _ => {}) {
    if (this.cognitoUser) {
      this.cognitoUser.getSession((err, session) => {
        debug('user.getSession(err, session)', err, session)
        if (err) {
          runInAction(() => {
            this.state = this.states.loggedOut
          })
          return callback(err)
        }
        debug('session.isValid()', session.isValid())
        this.cognitoUser.refreshSession(session.getRefreshToken(), (err, session) => {
          debug('user.refreshSession(err, session)', err, session)
          if (err) return callback(err)
          debug('session.isValid()', session.isValid())
          runInAction(() => {
            this.user = jwtDecode(session.idToken.jwtToken)
            if (session.isValid()) {
              this.token = session.idToken.jwtToken
              this.state = this.states.loggedIn
              this.idExpiration = session.idToken.payload.exp
              this.permissions = new Permissions(this.user['custom:permissions'] || '')
            } else {
              this.token = null
              this.state = this.states.loggedOut
              this.permissions = null
              this.stopRefreshing()
            }
          })
          callback()
        })
      })
    } else {
      runInAction(() => {
        this.state = this.states.loggedOut
      })
      callback(new Error('never authenticated'))
    }
  }

  signUp (email, password, first, last, callback) {
    // Normalize email as used for username, but preserve case for email
    const username = (email || '').toLowerCase()
    const atts = [
      new CognitoUserAttribute({ Name: 'email', Value: email }),
      new CognitoUserAttribute({ Name: 'given_name', Value: first }),
      new CognitoUserAttribute({ Name: 'family_name', Value: last })
      // new CognitoUserAttribute({Name: 'phone_number', Value: phone})
    ]
    storage.set('temporaryUsername', username)
    storage.set('lastPreLoginAction', 'signUp')
    this.userPool.signUp(username, password, atts, null, (err, result) => {
      debug('userPool.signUp', err, result)
      if (err) return callback(err)
      runInAction(() => {
        this.cognitoUser = result.user
        this.state = this.states.confirming
      })
      callback()
      // this.init(callback)
    })
  }

  logIn (email, password, callback) {
    // Normalize email as used for username
    const username = (email || '').toLowerCase()
    const cred = new AuthenticationDetails({ Username: username, Password: password })
    runInAction(() => {
      this.cognitoUser = new CognitoUser({ Username: username, Pool: this.userPool })
    })
    this.cognitoUser.authenticateUser(cred, {
      onSuccess: result => {
        debug('result', result)
        storage.remove('temporaryUsername')
        storage.remove('lastPreLoginAction')
        this.rootStore.init()
        this.init(callback)
      },
      onFailure: err => {
        if (err) {
          if (err.code === 'UserNotConfirmedException') {
            runInAction(() => {
              this.state = this.states.confirming
            })
          }
          callback(err)
        }
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        delete userAttributes.email_verified
        delete userAttributes.email
        runInAction(() => {
          this.state = this.states.newPasswordRequired
          this.attributeStash = userAttributes
        })
      }
    })
  }

  newPassword (newPassword) {
    return new Promise((resolve, reject) => {
      this.cognitoUser.completeNewPasswordChallenge(newPassword, this.attributeStash, {
          onSuccess: data => {
            debug('data', data)
            storage.remove('temporaryUsername')
            storage.remove('lastPreLoginAction')
            try {
              this.rootStore.init()
              this.init(function (err) {
                if (err) reject(err)
                resolve()
              })
            } catch (e) {
              reject(e)
            }
          },
          onFailure: reject
        })
    })
  }

  confirmRegistration (code, callback) {
    this.cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) return callback(err)
      debug('result', result)
      callback()
      runInAction(() => {
        this.state = this.states.loggedOut
      })
    })
  }

  resendConfirmationCode (callback) {
    this.cognitoUser.resendConfirmationCode((err, result) => {
      if (err) return callback(err)
      debug('result', result)
      callback()
    })
  }

  forgotPassword (username, callback) {
    storage.set('temporaryUsername', username)
    storage.set('lastPreLoginAction', 'forgotPassword')
    runInAction(() => {
      this.cognitoUser = new CognitoUser({ Username: username, Pool: this.userPool })
    })
    this.cognitoUser.forgotPassword({
      onSuccess: data => {
        debug('result', data)
        callback(null, data)
        runInAction(() => {
          this.state = this.states.resetPassword
        })
      },
      onFailure: err => {
        callback(err)
      }
    })
  }

  clearResetPassword () {
    runInAction(() => {
      storage.remove('lastPreLoginAction')
      this.lastPreLoginAction = 'signUp'
    })
  }

  confirmPassword (verificationCode, newPassword, callback) {
    this.cognitoUser.confirmPassword(
      verificationCode, newPassword, {
        onSuccess: data => {
          debug('result', data)
          this.clearResetPassword()
          callback(null, data)
          runInAction(() => {
            this.state = this.states.loggedOut
          })
        },
        onFailure: err => {
          callback(err)
        }
      })
  }

  logOut () {
    if (this.cognitoUser) {
      this.cognitoUser.storage.clear()
      this.cognitoUser.signOut()
      runInAction(() => {
        this.cognitoUser = null
        this.state = this.states.loggedOut
        window.location = window.location // eslint-disable-line no-self-assign
      })
    }
  }

  async changeDefaultSearch (Value) {
    const atts = [new CognitoUserAttribute({ Name: 'custom:defaultSearch', Value })]
    const self = this
    return new Promise(function (resolve, reject) {
      self.cognitoUser.updateAttributes(atts, (err, result) => {
        debug('user.updateAttributes', err, result)
        if (err) return reject(err)
        self.refresh(err => {
          if (err) return reject(err)
          resolve()
        })
      })
    })
  }

  changeAttribute (Name, Value, callback = _ => {}) {
    const atts = [new CognitoUserAttribute({ Name, Value })]
    this.cognitoUser.updateAttributes(atts, (err, result) => {
      debug('user.updateAttributes', err, result)
      if (err) return callback(err)
      this.refresh(err => {
        if (err) return callback(err)
        callback()
      })
    })
  }

  /*
  changeUserLocale (locale, callback) {
    const atts = [new CognitoUserAttribute({Name: 'locale', Value: locale})]
    localStorage.setItem('locale', locale)
    user.updateAttributes(atts, (err, result) => {
      debug('user.updateAttributes', err, result)
      if (err) return callback(err)
      callback()
    })
  }

  */
}

debug('loaded')
