import { extendObservable, runInAction } from 'mobx'
import testImage from '../scenes/Lite/testImage.png'
import ObjectId from 'bson'
import logo from '../logo.png'

const debug = require('debug')('qm:UserStore')

export default class LogoStore {
  constructor (rootStore) {
    this.rootStore = rootStore
    extendObservable(this, {
      logos: new Map(),
      error: null,
      loading: null,
      sort: 'created',
      get logosBySort () {
        const cmp = (a, b) => a[this.sort] > b[this.sort] ? -1 : a[this.sort] > b[this.sort] ? 1 : 0
        return [...this.logos.values()].sort(cmp)
      }
    })
  }

  get (logoId) {
    const logo = this.logos.get(logoId)
    return logo
  }

  replace (logos) {
    runInAction(() => {
      this.logos.replace(logos.map(l => {
        l.lastError = null // need to initialize properties for observed changes to propagate
        return [l.id, l]
      }))
    })
  }

  async fetch () {
    const { api } = this.rootStore
    debug({ api })
    runInAction(() => { this.loading = true; this.error = false })
    try {
      // const { logos } = await api.get('logos')
      const logos = [
        { id: new ObjectId().toString(), url: testImage, name: 'Citadel', created: (new Date()).toISOString() },
        { id: new ObjectId().toString(), url: logo, name: 'Quant Match', created: (new Date()).toISOString() },
        { id: new ObjectId().toString(), url: testImage, name: 'Quant Match', created: (new Date()).toISOString() },
        { id: new ObjectId().toString(), url: testImage, name: 'Quant Match', created: (new Date()).toISOString() }
      ]
      runInAction(() => {
        this.loading = false
        this.replace(logos)
      })
    } catch (e) {
      runInAction(() => {
        this.loading = false
        this.error = e
      })
    }
  }
}

debug('loaded')
