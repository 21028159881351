/* global fetch */

import { extendObservable, runInAction, autorun } from 'mobx'

const debug = require('debug')('qm:ApiStore')

function timeout (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export class APIError extends Error {
  constructor (message, statusCode, response) {
    super()
    this.name = 'APIError'
    this.message = message
    this.statusCode = statusCode
    this.response = response
  }
  toString () {
    return this.name + ': ' + this.message
  }
}

export default class ApiStore {
  constructor (rootStore, baseURL = '/api/v1.0/') {
    this.authStore = rootStore.auth
    this.baseURL = baseURL
    extendObservable(this, {
      outstandingRequests: 0
    })
    autorun(_ => debug('outstandingRequests', this.outstandingRequests))
  }

  async getToken () {
    if (this.authStore.cognitoUser) {
      const session = await new Promise((resolve, reject) => {
        this.authStore.cognitoUser.getSession((err, session) => {
          if (err) return reject(err)
          return resolve(session)
        })
      })
      return session.idToken.jwtToken
    }
    throw new Error('Not authenticated')
  }

  async request (
    path,
    method,
    data,
    triesAllowed = 1,
    delay = 500
  ) {
    const fullPath = this.baseURL + path.replace(/^\/+/, '')
    const token = await this.getToken()
    debug(method, fullPath, data, { triesAllowed, delay })
    try {
      runInAction(() => { this.outstandingRequests += 1 })
      const init = {
        method,
        headers: {
          Authorization: token,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
      if (data) init.body = JSON.stringify(data)
      debug('init', init)
      const response = await fetch(fullPath, init)
      if (response.ok) {
        return (method === 'PUT' || method === 'DELETE') ? true : response.json()
      }
      debug('response', response)
      throw new APIError(response.statusText, response.status, response)
    } catch (e) {
      debug('ERROR', method, fullPath, data, { triesAllowed, delay }, e)
      if (e.statusCode > 499 && triesAllowed - 1 > 0) {
        debug('waiting', delay)
        await timeout(delay)
        return this.request(path, method, data, triesAllowed - 1, delay * 2)
      }
      throw e
    } finally {
      runInAction(() => { this.outstandingRequests -= 1 })
    }
  }

  async get (path) { return this.request(path, 'GET', null, 3) }
  async put (path, data) { return this.request(path, 'PUT', data, 3) }
  async delete (path, data) { return this.request(path, 'DELETE', data, 3) }
  async post (path, data) { return this.request(path, 'POST', data) }
}

debug('loaded')
