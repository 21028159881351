import { extendObservable, runInAction } from 'mobx'
import { createTransformer } from 'mobx-utils'
import { union, intersection } from 'quantmatch-common'
import { ObjectID } from 'bson'
import qs from 'querystring'
import moment from 'moment'

const debug = require('debug')('qm:CandidateStore')

export default class CandidateStore {
  constructor (rootStore) {
    this.rootStore = rootStore
    extendObservable(this, {
      candidates: new Map(),
      loading: null,
      error: null,
      saving: null,
      saveError: null,
      sortedAvailability: createTransformer(candidate =>
        new Map([...candidate.availability.entries()]
          .map(([who, availability]) =>
            [who, (availability || [])
              .sort((a, b) => a.start === b.start ? 0 : a.start > b.start ? 1 : -1)]
          )
        )
      ),
      sortedTimeline: createTransformer(candidate =>
        [].concat(
          (candidate.notes || []).map(n => Object.assign({ kind: 'note', start: n.created }, n)),
          (candidate.statusHistory || []).map(s => Object.assign({ kind: 'status', start: s.updated }, s))
        ).sort((a, b) => a.start === b.start ? 0 : a.start > b.start ? -1 : 1)
      )
    })
  }

  key (searchID, personID) { return `${searchID}.${personID}` }
  get (searchID, personID) { return this.candidates.get(this.key(searchID, personID)) }

  // Transform array of candidates into internal map format
  replace (candidates = []) {
    runInAction(() => {
      this.candidates.replace(candidates.map(c => [this.key(c.searchID, c.personID), c]))
    })
  }

  // Add an array of candidates
  add (candidates = []) {
    debug('add', candidates)
    runInAction(() => {
      candidates.map(c => {
        debug('candidates c', c)

        // Translate availability into a map internally, translate dates into moments
        if (!c.availability || Array.isArray(c.availability)) {
          c.availability = new Map()
        } else {
          const avails = []
          debug('c.availability', c.availability)
          for (let who in c.availability) {
            if (!c.availability.hasOwnProperty(who)) continue
            // debug('who', {who, val:c.availability[who]})
            avails.push([
              who,
              c.availability[who].map(entry =>
                Object.assign(
                  entry, {
                    start: moment(entry.start),
                    end: moment(entry.end)
                  }
                )
              )
            ])
          }
          c.availability = new Map(avails)
        }
        return this.candidates.set(this.key(c.searchId, c.personId), c)
      })
    })
  }

  calculateCommonAvailability (candidate) {
    runInAction(() => {
      const A = [...candidate.availability.get('candidate') || []] || []
      const B = [...candidate.availability.get('company') || []] || []
      debug('availabilityIntersection', { A, B })
      const plainA = A.map(a => ({
        start: a.start.format('X'),
        end: a.end.format('X')
      }))
      const plainB = B.map(a => ({
        start: a.start.format('X'),
        end: a.end.format('X')
      }))
      const intersected = intersection(plainA, plainB)
      const restored = intersected.map(a =>
        Object.assign(a, {
          id: new ObjectID(),
          start: moment.unix(a.start),
          end: moment.unix(a.end)
        })
      )
      candidate.availability.set('common', restored)
    })
  }

  addAvailability (candidate, who, entry) {
    runInAction(() => {
      const myAvail = ([...candidate.availability.get(who) || []] || [])
      myAvail.push(entry)
      const plain = myAvail.map(a => ({
        start: a.start.format('X'),
        end: a.end.format('X')
      }))
      const flattened = union(plain)
      const restored = flattened.map(a =>
        Object.assign(a, {
          id: new ObjectID(),
          start: moment.unix(a.start),
          end: moment.unix(a.end)
        })
      )
      debug('addAvailability', myAvail, flattened, restored)
      candidate.availability.set(who, restored)
    })
    this.calculateCommonAvailability(candidate)
    this.saveAvailability(candidate)
  }

  removeAvailability (candidate, who, entry) {
    runInAction(() => {
      const myAvail = (candidate.availability.get(who) || [])
        .filter(e => e.id !== entry.id)
      candidate.availability.set(who, myAvail)
    })
    this.calculateCommonAvailability(candidate)
    this.saveAvailability(candidate)
  }

  async saveAvailability (candidate) {
    const { api } = this.rootStore
    runInAction(() => { this.saving = true; this.saveError = false })
    try {
      const query = {}
      if (this.rootStore.search.adminMode) query.adminMode = 'true'
      const s = qs.stringify(query)
      const q = s ? ('?' + s) : ''
      debug('qs', qs)
      const result = await api.put(`candidates/${candidate.searchId}/${candidate.personId}/availability` + q, candidate.availability)
      debug('result', result)
      runInAction(() => {
        this.saving = false
      })
    } catch (e) {
      debug(e)
      runInAction(() => {
        this.saving = false
        this.saveError = e
      })
    }
  }

  changeStatus (candidate, status) {
    const { auth, search } = this.rootStore
    const created = (new Date()).toISOString()
    const [updated, endDate] = [created, created]
    const by = { first: auth.user.given_name, last: auth.user.family_name }
    const statusEntry = { id: Math.random(), by, created, endDate, updated, note: null, status }
    runInAction(() => {
      candidate.statusHistory = [statusEntry, ...candidate.statusHistory]
    })
    this.saveStatus(candidate.searchId, candidate.personId, status).then(async () => {
      await this.fetch(candidate.searchId, candidate.personId)
      await search.fetchOne(candidate.searchId)
    })
  }

  async addNewCandidate (searchId, personId, status = 'Approach Assignment') {
    await this.saveStatus(searchId, personId, status)
    await Promise.all([
      this.fetch(searchId, personId),
      this.rootStore.search.fetchOne(searchId)
    ])
  }

  async saveStatus (searchId, personId, status) {
    const { api } = this.rootStore
    runInAction(() => { this.saving = true; this.saveError = false })
    try {
      const query = {}
      if (this.rootStore.search.adminMode) query.adminMode = 'true'
      const s = qs.stringify(query)
      const q = s ? ('?' + s) : ''
      debug('qs', qs)
      const result = await api.put(`candidates/${searchId}/${personId}/status` + q, { status })
      debug('result', result)
      runInAction(() => {
        this.saving = false
      })
    } catch (e) {
      debug(e)
      runInAction(() => {
        this.saving = false
        this.saveError = e
      })
    }
  }

  addFeedback (candidate, entry) {
    runInAction(() => {
      if (candidate.notes && candidate.notes.length) candidate.notes.push(entry)
      else candidate.notes = [entry]
    })
    this.saveFeedback(candidate, entry.note)
  }

  async saveFeedback (candidate, feedback) {
    const { api } = this.rootStore
    runInAction(() => { this.saving = true; this.saveError = false })
    try {
      const query = {}
      if (this.rootStore.search.adminMode) query.adminMode = 'true'
      const s = qs.stringify(query)
      const q = s ? ('?' + s) : ''
      debug('qs', qs)
      const result = await api.post(`candidates/${candidate.searchId}/${candidate.personId}/feedback` + q, { feedback })
      debug('result', result)
      runInAction(() => {
        this.saving = false
      })
    } catch (e) {
      debug(e)
      runInAction(() => {
        this.saving = false
        this.saveError = e
      })
    }
  }

  updateNote (candidate, text) {
    if (candidate.note !== text) {
      runInAction(() => { candidate.note = text })
      clearTimeout(this.noteTimeout)
      this.noteTimeout = setTimeout(_ => {
        this.saveNote(candidate, text)
      }, 500)
    }
  }

  async saveNote (candidate, note) {
    const { api } = this.rootStore
    runInAction(() => { this.saving = true; this.saveError = false })
    try {
      const query = {}
      if (this.rootStore.search.adminMode) query.adminMode = 'true'
      const s = qs.stringify(query)
      const q = s ? ('?' + s) : ''
      debug('qs', qs)
      const result = await api.put(`candidates/${candidate.searchId}/${candidate.personId}/note` + q, { note })
      debug('result', result)
      runInAction(() => {
        this.saving = false
      })
    } catch (e) {
      debug(e)
      runInAction(() => {
        this.saving = false
        this.saveError = e
      })
    }
  }

  async fetchAllForSearch (searchID) {
    const { api } = this.rootStore
    if (this.loading) return
    runInAction(() => { this.loading = true; this.error = false })
    try {
      const query = {}
      if (this.rootStore.search.adminMode) query.adminMode = 'true'
      if (!this.rootStore.search.has(searchID)) query.includeSearch = 'true'
      query.includePeople = 'true'
      const s = qs.stringify(query)
      const q = s ? ('?' + s) : ''
      debug('qs', qs)
      const { candidates, people, search, peopleOrgs, searchOrg } = await api.get(`candidates/${searchID}` + q)
      debug('returned', { candidates, people, search, peopleOrgs, searchOrg })
      this.add(candidates)
      if (people) this.rootStore.person.add(people)
      if (search) this.rootStore.search.add([search])
      if (peopleOrgs) this.rootStore.organization.add(peopleOrgs)
      if (searchOrg) this.rootStore.organization.add([searchOrg])
      runInAction(() => {
        this.loading = false
      })
    } catch (e) {
      debug(e)
      runInAction(() => {
        this.loading = false
        this.error = e
      })
    }
  }

  async fetch (searchID, personID) {
    const { api } = this.rootStore
    if (this.loading) return
    runInAction(() => { this.loading = true; this.error = false })
    try {
      const query = {}
      if (this.rootStore.search.adminMode) query.adminMode = 'true'
      if (!this.rootStore.search.has(searchID)) query.includeSearch = 'true'
      if (!this.rootStore.person.get(personID)) query.includePerson = 'true'
      const s = qs.stringify(query)
      const q = s ? ('?' + s) : ''
      debug('qs', qs)
      const { candidate, person, search, personOrg, searchOrg } = await api.get(`candidates/${searchID}/${personID}` + q)
      debug('returned', { candidate, person, search, personOrg, searchOrg })
      this.add([candidate])
      if (person) this.rootStore.person.add([person])
      if (search) this.rootStore.search.add([search])
      if (personOrg) this.rootStore.organization.add([personOrg])
      if (searchOrg) this.rootStore.organization.add([searchOrg])
      runInAction(() => {
        this.loading = false
      })
    } catch (e) {
      debug({ e })
      runInAction(() => {
        this.loading = false
        this.error = e
      })
    }
  }
}

debug('loaded')
